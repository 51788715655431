.panels {
   margin: 5px 1px 0px;
   padding: 0;
   float: none;
   width: auto;
   h3 {
      display: none;
   }
}

.panel-holder {
   padding: 0;
	@media @desktop {
		width: 33.33333%;
	}
	@media @tablet {
		width: 50%;
	}
	@media @mobile {
		width: 100%;
	}
}

.panel {
   padding: 0;
   position: relative;
   margin-bottom: 5px;
   overflow: hidden;
   min-height: 197px;
   border:	1px solid #eaeaea;
   border-radius:0;
   will-change: transform;
   box-shadow: none;
   border: 0;
   &:hover {
      span.readmore {
         &:before {
            transform: scaleY(10);
         }
      }
      // img {
      //    transform: scale(1.01);
      // }
   }
   img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      object-position: top;
      transition: all ease .3s;
   }
}

span.readmore {
   background: none;	
   color: #fff;
   font-size: 1.1em;
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
   right: 0;
   border: none;
   text-align: left;
   z-index: 1;
   padding: 8px 0px 8px 20px;
   will-change: transform;
   &:before {
      content: '';
      position: absolute;
      background-color: fade(@c-secondary, 80%);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      transition: all cubic-bezier(1, 0.24, 0.2, 0.96) .4s;
      will-change: transform;
   }
}
.panel-holder:nth-child(odd) span.readmore:before {background:@c-main;opacity:0.8}
.panel-holder:nth-child(even) span.readmore {background:@c-secondary;opacity:0.8}
.imagediv {
    height: 345px;
    display: block;
    background-repeat: no-repeat;
    background-position: top center;
    -moz-background-size: cover;
    background-size: cover;
}
.lowerpanels .panel-holder:nth-child(even) span.readmore {background:@c-main;opacity:0.8}
.lowerpanels .panel-holder:nth-child(odd) span.readmore {background:@c-main;opacity:0.8}

.lowerpanels .panel-holder:nth-child(even):hover span.readmore{background:@c-secondary;opacity:0.8}
.lowerpanels .panel-holder:nth-child(odd):hover span.readmore{background:@c-secondary;opacity:0.8}

// .lowerpanels .panel-holder:nth-child(even) span.readmore:before {background:transparent}
// .lowerpanels .panel-holder:nth-child(odd) span.readmore:before {background:transparent}

.TeamAccord img {width:100%;}
.accordname {
    background: @c-textcolor;
    color: #fff;
    padding-left:10px;
    display: block;
    width: 90%;
    margin: 0 auto;
    bottom: 18px;
    z-index: 10;
    position: relative;
    line-height: 3.5rem;
}


/* GM edits */
.beatson--autolinks a {
    background-color: #0391bf;
    padding: 0.5em 0.5em;
    float: left;
    width: 100%;
    color: #fff;
}

.beatson--autolinks a:hover {
    background-color: #b80068;
    text-decoration: none;
}

.beatson--autolink {
    padding: 0.3em 0.2em 0.3em 0;

    @media @mobile {
        width: 100%;
        margin-bottom: 10px;
    }
}

.beatson--autolinks a i.fa.fa-link {
    float: right;
}