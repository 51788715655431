.promotional-panel {
   background-color: @c-secondary-fade;
   padding: 30px;
   text-align: center;
   margin: 30px 0;
}

.promotional-panel__heading {
   color: @c-main;
}

.promotional-panel__link {
   font-weight: 600;
   font-size: 1.8rem;
   color: @c-secondary;
}