.home-search {
   background-color: @c-secondary-fade;
   padding: 30px 0;
   position: relative;
   margin: 30px 0;
   &:before {
      content: '';
      position: absolute;
      top: 0;
      right: -9999px;
      bottom: 0;
      left: -9999px;
      background-color: @c-secondary-fade;
      z-index: -1;
   }
}

.home-search__container {
   display: flex;
   justify-content: space-between;
   @media @tablet {
      flex-wrap: wrap;
   }
}

.home-search__field {
   flex: 1 1 33.3333%;
   margin: 0 -1px;
   &:first-of-type {
      margin-left: 0;
   }
   &:last-of-type {
      flex: 1 1 300px;
      margin-right: 0;
   }
   @media @tablet {
      flex: 1 0 100%;
      margin: 0 0 10px 0;
      &:last-of-type {
         margin-bottom: 0;
         width: 100%;
      }
   }
}

.home-search__input {
   padding: 7px 10px;
   border: 1px solid #D1D2D4;
   width: 100%;
   min-height: 40px;
   &::placeholder {
      color: #C7C8CA;
   }
}

.home-search__button {
   width: 100%;
   background-color: @c-main;
   color: #FFF;
   border: 0;
   padding: 7px 10px;
   min-height: 40px;
   .transition;
   cursor: pointer;
   display: flex;
   align-items: center;
   text-align: center;
   justify-content: center;
   &:hover {
      background-color: lighten(@c-main, 10%);
   }
   .glyphicon {
      font-size: 1.5rem;
      margin-right: 10px;
   }
}

