// body {
//     color: @c-textcolor;font-family:Arial;
// }
h1, h2, h3, h4 {
    color: @c-main;
    margin: 20px 0;
    line-height: 1.4;
    font-weight: 600;

    &:first-of-type {
        margin-top: 0px;
    }

    span {
        font-size: inherit !important;
    }
}

h1 {
	// margin-bottom: 20px;
	font-size: 3.8rem;
	line-height: 1.3;

    @media @mobile {
        font-size: 2.2rem;
    }
}

h2 {
    color: @c-textcolor;
    font-size: 1.7em;

    @media @mobile {
        font-size: 2rem;
    }
}

a {
	text-decoration-skip: ink;
}

p {
	font-size: 1em;
	line-height: 1.25em;
	margin: 20px 0;
    
	&:first-of-type {
		margin-top: 0;
	}
	// &:last-of-type {
	// 	margin-bottom: 0;
	// }
}

span.preamble {
	font-size: 2.3rem;
	color: @c-secondary;
	line-height: 1.4;
}

span.quote-text {
	font-size: 2.6rem;
	color: @c-main;
	line-height: 1.4;
	&:before {
		content: open-quote;
	}
	&:after {
		content: close-quote;
	}
}

ul {
    

    li {
        font-size: 1em;
        line-height: 1.25em;
        margin-bottom: 0px;
        

        :before {
          //  content: "\2022"; 
            color: @c-main; 
            display: inline-block;
            width: 1em;
            margin-left: -1em; 
        }
    }
}
ul.quicklink ul li:before {content:"";}
span.IntroText {font-size:110%; line-height:1.25;}
br {
    line-height: 150%;
    content:"";
    display:block;
    margin-top:10px;
}