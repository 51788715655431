.transition {
	transition: all ease .3s;
}

.no-margin {
	margin: 0;
}

.mobile--only {
	display: none !important;
	@media @tablet {
		display: block !important;
	}
}