.select-container {
	position: relative;
	&:after {
		content: '\e114';
		font-family: 'Glyphicons Halflings';
		position: absolute;
		top: 11px;
		right: 15px;
		font-size: 1.2rem;
		color: @c-secondary;
		pointer-events: none;
	}
	select {
		-ms-appearance: none;
		appearance: none;
		padding-right: 35px;
	}
	select::-ms-expand{
		display:none;
	 }
}

.input-container {
	position: relative;
}

input, select {
   outline: none;
}