.accordion-container {
   margin-bottom: 5px;
}

// .accordion-container--active {
//     .accordion__title {
//         &:after {
//             transform: translateY(-50%) rotate(0deg);
//         }
//     }

//     .accordion__content {
//         max-height: 10000px;
//         padding: 20px;

//         &.month {
//             display: none;
//         }

//         &.month.active {
//             display: block;
//         }
//     }
// }

.accordion__title {
    background-color: @c-main;
    color: #fff;
    padding: 10px 40px 10px 20px;
    font-size: 1.8rem;
    cursor: pointer;
    line-height: 1;
    position: relative;
    font-weight: 600;
    .transition;

    @media @mobile {
        font-size: 1.3rem;
    }

    &--active {
        &:after {
            transform: translateY(-50%) rotate(0deg);
        }
    }


    &:hover {
        background-color: lighten(@c-secondary-light, 5%);
    }

    &:after {
        content: '\e114';
        font-family: 'Glyphicons Halflings';
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%) rotate(-90deg);
        font-size: 1.5rem;
        .transition;
    }
}
.accordion-container {
    &:nth-child(odd) {
        .accordion__title {
            background-color: @c-secondary;

            &:hover {
                background-color: lighten(@c-secondary, 35%);
            }
        }
    }

    &:not(:only-of-type) {
        &:nth-child(odd) {
            .accordion__content {
                background-color: lighten(@c-secondary, 60%);

                h1, h2, h3, h4, h5 {
                    color: @c-secondary;
                }
            }
        }
    }
}

.accordion__content {
    padding: 0 20px;
    background-color: @c-secondary-fade;
    max-height: 0;
    overflow: hidden;
    .transition;

    &--active {
        max-height: 10000px;
        padding: 20px;

        &.month {
            display: none;
        }

        &.month.active {
            display: block;
        }
    }

    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    img {
        max-width: 100%;
    }
}

.newsaccord .accordion__title {
    background-color: #fff;
    color: @c-textcolor;
    padding: 10px 20px 10px 0;
    font-size: 1.8rem;
    cursor: pointer;
    line-height: 1;
    position: relative;
    font-weight: 600;
    font-size: 1.7em;
    border-bottom: 3px solid @c-main;
    .transition;


    &:hover {
        background-color: lighten(@c-secondary-light, 5%);
    }

    &:after {
        content: '\e114';
        color: @c-main;
        font-family: 'Glyphicons Halflings';
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        
        font-size: 1.5rem;
        .transition;
    }
}
.newsaccord.accordion-container:nth-child(odd) .accordion__title {background:#fff;}
.newsaccord.accordion-container:nth-child(even) .accordion__title {background:#fff;}

.newsaccord .accordion__title.month {border-bottom:3px solid @c-secondary;}
.newsaccord .accordion__content.month span {width:100%; display:block;}
.newsaccord .accordion__content.month span a {color:@c-textcolor; font-weight:600; display:block; margin-bottom:5px;font-size:1.6rem;}
.newsaccord .accordion__title.month:after {
    color: @c-secondary;
    font-size: 80%;
}
    .newsaccord .accordion__content {
        background: #fff;
        padding: 10px 0 0px 10px;
    }

    .newsaccord .accordion-container:nth-child(odd) .accordion__title {
        background-color: #fff;
        color: @c-secondary;
        font-size: 1.7rem;

        &:hover {
            background-color: #fff;
        }
    }


    .faq {
        min-height: initial;
    }

    .faq .FAQAnswerCaption {
        padding: 10px 10px 0;
        /*font-size: 1.2em;*/
        font-weight: 300;
        padding-bottom: 7px;
    }

    .faq .FAQAnswerFull {
        display: none;
        background-color: #f9f9f9;
        padding: 20px;
        margin-bottom: 40px;

        h1, h2, h3, h4, h5 {
            padding-left: 0;
        }
    }

    .faq .faqTitleWrapper h4 {
        min-height: inherit;
        padding: 0;
        background-image: none;
        background-color: #eee;
        margin-bottom: 0;
        // font-weight: 500;
        font-size: 2rem;
    }

    .faq .faqTitleWrapper i {
        position: absolute;
        top: 20px;
        left: 15px;
        font-size: 21px;
    }

    .faq .faqTitleWrapper a {
        color: #323137;
        padding: 10px 20px;
        width: 100%;
        display: block;
        position: relative;
        text-decoration: none;
    }

    .faq .faqTitleWrapper a:after {
        content: '\e114';
        font-family: 'Glyphicons Halflings';
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%) rotate(-90deg);
        font-size: 1.5rem;
        .transition;
    }

    .faq .active a:after {
        transform: translateY(-50%) rotate(0deg);
    }


.accordion__date {
    display: inline !important;
    width: auto !important;
    font-size: 12px;
}