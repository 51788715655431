
.carouselbuttons {
    text-align: center;
    width: 100%;
    display: block;
    position: absolute;
    left:0;
    right:0;
    bottom:70px;
    z-index:2;
    .pushme { 
        padding:10px 25px; margin:0 10px; background:rgba(255,255,255,0.85);font-size:2rem; font-weight:600;
        @media @tablet {
            display: block;
            margin-top: 10px;
        }

        @media @mobile {
            font-size: 1.5rem;
        }
    }
    .patients { color:@c-main; }
    .professionals {color:@c-secondary; }
}
.carousel {
	// min-height: 400px;
	margin-bottom: 5px;
	overflow: hidden;
    border-top:5px solid @c-secondary;
	// @media @tablet {
	// 	min-height: 300px;
	// }
	// @media @mobile {
	// 	min-height: 250px;
	// }
}

.carousel-caption {
	z-index: 10;	
	h2 { color:inherit;}
}

.carousel .item {
	// min-height: 400px;
    // z-index:-1;
    background-color: #777;
    padding-top: 37.38%;
	// @media @tablet {
	// 	min-height: 300px;
	// }
	// @media @mobile {
	// 	min-height: 250px;
    // }
    &--video {
        padding-top: 52.5%;
    }
    &--homepage {
        min-height: 300px;
    }
}

.carousel-inner>.item>a>img,.carousel__video, .carousel-inner>.item>img,.carousel__video {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: 100% !important;
	width: 100%;
	object-fit: cover;
	min-width: 100%;
}

.video-container {
    padding-top: 52.5%;
    position: relative;
}