.job-entry {
   margin-bottom: 30px;
}

.job-entry--full {
   .job-entry__content {
      padding: 0;
      background: none;
   }
   .job-entry__details {
      max-height: none;
   }
}

.job-entry--small {
   margin-bottom: 10px;
   .job-entry__heading {
      &:after {
         transform: translateY(-50%);
      }
   }
   .job-entry__details {
      // display: none;
      max-height: 0;
   }
   .job-entry__content {
      background-color: #FFF;
      padding: 15px;
   }
}

.job-entry__heading {
   background-color: @c-secondary-light;
   color: #000;
   padding: 10px 20px;
   margin: 0;
   font-size: 2.2rem;
   position: relative;
   cursor: pointer;
   @media @mobile {
      font-size: 1.8rem;
   }
   &:after {
      content: '\e080';
      font-family: 'Glyphicons Halflings';
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%) rotate(90deg);
      font-size: 1.3rem;
      pointer-events: none;
      .transition;
   }
}

.job-entry__heading-small {
   color: darken(@c-secondary-light, 20%);
   font-size: 1.7rem;
   border-bottom: 2px solid #D1D2D4;
   margin: 30px 0 10px !important;
   display: block;
}

.job-entry__content {
   padding: 20px;
   background-color: @c-secondary-fade;
   .transition;
   b {
      color: @c-secondary;
   }
   a:not(.job-entry__button) {
      font-weight: 600;
      text-decoration: underline;
   }
}

.job-entry__details {
   max-height: 3000px;
   overflow: hidden;
   .transition;
}

.job-entry__info-container {
   display: flex;
   flex-wrap: wrap;
   @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: block;
   }
}

.job-entry__info {
   flex: 1 0 50%;
   display: flex;
   margin: 3px 0;
   @media @tablet {
      flex: 1 0 100%;
      flex-wrap: wrap;
   }
}

.job-entry__label {
   font-weight: 600;
   color: @c-secondary;
   flex: 0 0 100px;
   display: inline-block;
}

.job-entry__bold {
   font-weight: 600;
   color: @c-secondary;
   margin: 3px 0;
}

.job-entry__flex {
   display: flex;
   @media @mobile {
      display: block;
   }
}

.job-entry__icon {
   width: 46px;
   height: 46px;
   margin-right: 20px;
   display: block;
   @media @mobile {
      margin: 0 0 10px 0;
   }
}

.job-entry__buttons {
   display: flex;
   margin: 30px -5px 0;
   @media @mobile {
      flex-wrap: wrap;
   }
}

.job-entry__button {
   background-color: @c-main;
   color: #FFF;
   display: block;
   padding: 15px 25px;
   text-align: center;
   flex: 1 1 50%;
   margin: 0 5px;
   font-weight: 600;
   cursor: pointer;
   .transition;
   @media @mobile {
      flex: 1 0 100%;
      margin-bottom: 10px;
      &:last-of-type {
         margin-bottom: 0;
      }
   }
   &:hover {
      background-color: lighten(@c-main, 5%);
      text-decoration: none;
      color: #FFF;
   }
   &:focus {
      text-decoration: none;
      color: #FFF;
   }
}

.job-entry__button--blue {
   background-color: @c-secondary;
   &:hover {
      background-color: lighten(@c-secondary, 5%);
   }
}

.job-sticky {
   position: sticky;
   top: 30px;
   @media @desktop {
      position: relative;
      top: 0;
      margin-bottom: 30px;
   }
}

.job-search {
   background-color: @c-secondary-fade;
   padding: 20px;
   position: relative;
   .transition;
   @media @tablet {
      overflow: hidden;
      max-height: 163px;
      &:before {
         content: '';
         position: absolute;
         bottom: 0;
         right: 0;
         left: 0;
         top: 0;
         background-color: fade(@c-secondary-fade, 70%);
         // height: 120px;
         // background: linear-gradient(180deg, fade(@c-secondary-fade, 0%) 0%, @c-secondary-fade 70%);
         z-index: 10;
         .transition;
      }
   }
   input, select {
      padding: 7px 35px 7px 10px;
      margin-bottom: 10px;
      border: 1px solid #D1D2D4;
      width: 100%;
   }
}

.job-search--expanded {
   max-height: 800px;
   padding-bottom: 75px;
   &:before {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
   }
   .job-search__expand {
      bottom: 20px;
      top: auto;
      transform: translateX(-50%);
      &:after {
         content: 'Tap to hide filters';
      }
      &:before {
         transform: scaleY(-1);
      }
   }
}

.job-search__expand {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   text-align: center;
   z-index: 11;
   font-weight: 600;
   background-color: @c-secondary;
   color: #FFF;
   padding: 10px 25px;
   border-radius: 30px;
   font-size: 1.3rem;
   box-shadow: 0px 3px 2px -1px rgba(0, 0, 0, .1);
   min-width: 185px;
   cursor: pointer;
   display: flex;
   align-items: center;
   .transition;
   display: none;
   @media @tablet {
      display: block;
   }
   &:before {
      content: '\e114';
      font-family: 'Glyphicons Halflings';
      margin-right: 10px;
      font-size: 1rem;
      display: inline-block;
      .transition;
   }
   &:after {
      content: 'Tap to expand filters';
   }
}

.job-search__heading {
   color: #000;
   font-size: 2.2rem;
   margin-bottom: 0;
}

.job-search__sub-heading {
   color: @c-secondary;
   font-size: 1.6rem;
   margin-bottom: 10px;
}

.job-search__label {
   // display: block;
   margin-right: 10px;
}

.job-search__flex {
   @media @tablet {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
      .job-search__field {
         flex: 1 1 ~"calc(50% - 10px)";
         margin: 0 5px;
      }
   }
   @media @mobile {
      display: block;
   }
}

.job-search__field {
   position: relative;
   input {  
      padding-right: 40px;
   }
}

.job-search__clear {
   position: absolute;
   top: 11px;
   right: 15px;
   font-size: 1.2rem;
}

.job-search__button {
   background-color: @c-main;
   color: #FFF;
   padding: 10px 25px;
   border: 0 !important;
   font-size: 1.7rem;
   margin-top: 10px;
   cursor: pointer;
   display: flex;
   align-items: center;
   text-align: center;
   justify-content: center;
   .transition;
   &:hover {
      background-color: lighten(@c-main, 10%);
   }
   .glyphicon {
      font-size: 1.5rem;
      margin-right: 10px;
   }
}