.navbar {
    background-color: #fff;
    border-radius: 0;
    // z-index: 1;
    border: 0;
    margin-bottom: 0px;
    min-height: auto;


    .navbar-nav {
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;


        @media @tablet {
            display: block;
            overflow-x: hidden;
        }

        &:before, &:after {
            content: '';
        }

        ul {
            list-style-type: none;
        }


        li.home {
            flex: 0 1 50px;

            @media @tablet {
                // display: none;
            }
        }

        li {
            line-height: auto;
            margin-bottom: 0;
            flex: 1;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            border-top: 1px solid #ccc;
            margin-right: 3px;
            padding-bottom: 0px;

            @media @tablet {
                border-left: 0;
                border-right: 0;
                margin: 0;
            }

    
      



            :before {

                @media @tablet {
                    display: none;
                }
            }



            &.open {
                a {
                    color: @c-secondary;
                    border-top: 3px solid @c-secondary;
                    background: #fff;

                    @media @tablet {
                        color: @c-secondary;
                        border-top: 3px solid @c-secondary;
                        background: #fff;
                    }
                }

                a.dropdown-toggle:after {
                    transform: scaleY(-1);
                }
            }

            &.active {
                a {
                    color: @c-secondary;
                    border-top: 3px solid @c-secondary;
                    background: #fff;
                    font-weight: 600;


                    &:hover {
                        color: @c-secondary;
                        border-top: 3px solid @c-secondary;
                        background: #fff;
                        font-weight: 700;
                    }

                    @media @tablet {
                        color: @c-secondary;
                        border-top: 3px solid @c-secondary;
                        background: #fff;
                        font-weight: 600;
                    }
                }
            }

            a {
                color: @c-main;
                font-size: 1.3rem;
                position: relative;
                z-index: 1;
                //height: 100%;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                padding: 6px 5px;
                cursor: pointer;
                user-select: none;
                border-top: 3px solid #fff;
                font-weight: 600;
                line-height: 1.2;



                @media @tablet {
                    justify-content: left;
                    text-align: left;
                    color: @c-main;
                    padding: 12px 20px;
                }

                &:hover {
                    color: @c-secondary;
                    border-top: 3px solid @c-secondary;
                    // background-color: lighten(@c-main, 7%);
                    @media @tablet {
                        background: none;
                        color: #000;
                    }
                }

                &:focus {
                    color: @c-secondary;

                    @media @tablet {
                        color: #000;
                    }
                }

                &.current {
                    font-weight: 600;
                }
            }

            a.dropdown-toggle {
                &:after {
                    // content: '\e114';
                    // font-family: 'Glyphicons Halflings';
                    // font-size: 1rem;
                    // margin-left: 10px;
                    // display: inline-block;
                    // .transition;
                }
            }
        }
    }

    .navbar-header {
        div {
            float: right;
            color: #fff;
        }

        .nav-title {
            padding: 12px 14px;
            font-weight: 600;
        }
    }
}

.navbar-default {
    .navbar-collapse {
        padding: 0 5%;

        @media @tablet {
            background-color: @c-secondary-fade;
            border-bottom: 2px solid darken(@c-secondary-fade, 10%);
            padding: 0;
            box-shadow: none;
        }
    }

    .navbar-toggle .icon-bar {
        background-color: #fff;
    }
    // .navbar-toggle:focus,
    .navbar-toggle {
        border: 0;
        background-color: lighten(@c-main, 10%);

        &:hover {
            background-color: lighten(@c-main, 15%);
        }
    }

    .navbar-nav > .open > a,
    .navbar-nav > .open > a:focus,
    .navbar-nav > .open > a:hover {
        border-top: 3px solid @c-secondary;
        color: @c-secondary;
        background-color: #fff;
        font-weight: 700;

        @media @tablet {
            color: @c-secondary;
        }
    }

    ul.dropdown-menu {
        box-shadow: none;
        border-radius: 0;
        background-color: rgba(255,255,255,0.85);
        border: 0;
        width: 80%;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 0px 3%;
        display: block;
        transform: translateY(-100%);
        z-index: -1;
        min-height: 400px;
        height: auto;
        .transition;
        // Craig
        //position:relative;
        // @media @tablet {
        //     margin-left: 20px;
        //     border-left: 2px solid @c-secondary !important;
        // }

        li {
            background: none;
            cursor: pointer;
            border-left: none;
            border-right: none;
            border-top: none;
            line-height:0.8;
            margin-bottom: 10px;

            @media @tablet {
                margin-bottom: 20px;
                line-height: 1.2;
            }
        }

        a {
            width: 33%;
            padding: 5px 0;
            display: block;
            // font-weight: 600;
            color: #000;
            justify-content: left;
            text-align: left;
            background: transparent;
            border-top: none;

            @media @tablet {
                font-weight: 400;
            }

            &:hover {
                text-decoration: none;
                color: @c-secondary !important;
                background: transparent;
                border-top: none;
                font-weight: 700;

                &:before {
                    display: block;

                    @media @tablet {
                        display: none;
                    }
                }
            }

            &:focus {
                text-decoration: none;
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                display: none;
            }
        }

        .sub-dropdown {

            > a {
                &:after {
                    //content: '\e080';
                    //font-family: 'Glyphicons Halflings';
                    content: '>';
                    font-size: 1.5rem;
                    display: inline-block;
                    margin-left: 10px;
                    float: right;
                    margin-right: 50px;
                    .transition;
                }
            }

            .dropdown {
                list-style-type: none;
                padding-left: 20px;
                margin-top: 0px;

                @media @tablet {
                    padding-left: 0;
                    margin: 20px 0;
                }
            }
        }

        .sub-dropdown--active {
            > a {
                font-weight: 600;
                color: @c-secondary;

                &:after {
                    transform: scaleY(-1);
                }
            }
        }

        ul.dropdown {
            position: absolute;
            top: 20px;
            left: 33%;
            width: 33%;
            display: none;

            @media @tablet {
                position: relative;
                left: 0;
                width: 100%;
                top: 0;
            }

            &.dropdown--open {
                display: block;

                @media @tablet {
                    border-left: 2px solid @c-main;
                }
            }

            ul.dropdown {
                display: none;

                &.dropdown--open {
                    display: block;

                    @media @tablet {
                        border-left: 2px solid @c-main;
                    }
                }
            }
        }

        @media @tablet {
            transform: none;
            display: none;
            padding: 0;
        }
    }

    li.dropdown {
        position: static;

        @media @tablet {
            position: relative;
        }

        &.open {
            a.dropdown-toggle {
                //background-color: lighten(@c-main, 10%);
                // font-weight: 600;
                // color: @c-secondary;
                @media @tablet {
                    //	background-color: @c-secondary-fade;
                }
            }

            ul.dropdown-menu {
                transform: translateY(0);

                @media @tablet {
                    transform: none;
                    display: block;
                }
            }
        }
    }
}


.breadcrumb {
    background-color: transparent;
    padding-left: 0;
    display: inline;
    margin-bottom: 0px;

    ul {
        @media @tablet {
            padding-left: 0;
        }
    }

    li {
        margin-bottom: 0;
        width: auto;
        display: inline;
        color: #444444;

        :before {
            content: "";
        }
    }

    a {
        color: #444444;
        font-size: 12px;
    }
}


.navbar-default ul.dropdown li .ulsubmenu1 li a {
    color: #000;
    font-weight: 300;
}

.navbar-default .nav li.dropdown .submenu1 .sub-dropdown a {
    color: #000;
    font-weight: 300;
    background: transparent;
    border-top: none;
}

.navbar-default .nav li.dropdown .submenu1 .submenu2li a {
    color: #000;
    font-weight: 300;
    background: transparent;
    border-top: none;
    padding: 0px;
    margin: 0px;
    width: 100%;
    line-height:1;

    :hover {
        font-weight: 700;
    }
    
}
.navbar-default .nav li.dropdown .submenu1 .submenu2li a.active {
    font-weight: 700;
    color: @c-secondary;
}
.navbar-default .nav li.dropdown .submenu1 .submenu2li a:hover {
    font-weight: 700;
}
    li.submenu3 {
        position: relative;
        left: 33%;
        top: 0px;
    }

    .submenu1li {
        @media @tablet {
            margin: 0 0 10px !important;
        }
    }

    .navbar-default ul.dropdown-menu li.submenu1li a {
        color: #000 !important;
        font-weight: 300 !important;
        background: transparent !important;
        border-top: none !important;
        width: 100%;
    }

    .navbar-default .nav li.dropdown .submenu1 .submenu2li {
        width: 33%;
         padding-top:0px; padding-bottom:0px;

         @media @tablet {
            width: 100%;
            padding-left: 25px;
         }
    }
    .navbar-default .nav li.dropdown .submenu2 .submenu2li {
        width: 50%;
        @media @tablet {
            width: 100%;
         }
    }

    .navbar-default .nav li.dropdown .submenu1 .submenu2li a:hover {
        font-weight: 700;
    }

    .submenu3li {
        @media @tablet {
            width: 100%;
            padding-left: 25px;
            margin-bottom: 8px;
         }
    }


    .navbar-default ul.dropdown-menu.submenu1 {
        min-height: 500px;
    }

    ul.navbar-nav .dropdown {
        width: 100%;
    }

    ul.navbar-nav .dropdown ul.submenu1 {
        width: 100% !important
    }

    ul.navbar-nav .dropdown ul.submenu1 {
        min-height: 400px;
        border-top: 3px solid @c-secondary;
        height:auto;
        max-width: 1072px;
        padding-top: 10px;

        @media @tablet-min {
            overflow-y: auto;
            max-height: 406px;
        }

        @media @tablet {
            min-height: auto;
            padding: 20px 0;
        }
    }

    ul.navbar-nav .dropdown ul.submenu2 {
        width: 66%;
        min-height: 300px;
        margin-top:-20px;

        @media @tablet {
            width: 100%;
            min-height: auto;
        }
    }
ul.navbar-nav .dropdown ul.submenu3 {
    width: 50%;

    @media @tablet {
        width: 100%;
    }
    
    
}
    ul.navbar-nav .dropdown ul.submenu2 li.submenu2li {
    }

    ul.navbar-nav .dropdown ul.submenu2 li.submenu2li a {
        width: 100% !important;
    }

.navbar-default ul.dropdown-menu ul.submenu3 {
    left:50%;
    @media @tablet {
        left: 0;
    }
}
.navbar-default .nav li.dropdown .submenu1 .submenu2li.sub-dropdown--active a {font-weight:700; color:@c-secondary;}
.navbar-default .nav li.dropdown .submenu1 .submenu2li.sub-dropdown--active .dropdown a {font-weight:300; color:#000;}

.navbar-default .nav li.dropdown .submenu1 .submenu2li.sub-dropdown--active .dropdown .sub-dropdown--active a {
    font-weight: 700;
    color: @c-secondary;
}
.navbar-default .nav li.dropdown .submenu1 .submenu2li.sub-dropdown--active .dropdown.submenu3 a {
    font-weight: 300;
    color: #000;
}
ul.navbar-nav .dropdown ul.submenu1:before {
    content:"";
    border-right: 1px solid #ccc;
    min-height: 360px;
    position: absolute;
    left: 33%;
    top:20px;

    @media @tablet {
        display: none;
    }
}
ul.navbar-nav .dropdown ul.submenu2:before {
    content: "";
    border-right: 1px solid #ccc;
    min-height: 360px;
    position: absolute;
    left: 50%;
    top: 10px;
    @media @tablet {
        display: none;
    }
}
ul.navbar-nav .dropdown ul.submenu3 {
    top:0;
    @media @tablet {
        top: 0;
        margin-top: 10px;
    }
}

#searchTerm {border:1px solid @c-main;color:#000; text-align:center; font-size:1.2rem;padding:5px; width:80%;}
.submitsearch { background:#fff; border:none; color:@c-secondary;font-size:2.2rem;}
.findUs {font-weight:700; color:@c-main;font-size:1.4rem; line-height:2.5em;}
.findUsIcon .fa-map-marker {font-weight:700; color:@c-secondary;font-size:2.6rem; margin-left:10px;}
.findUsIcon .fa-map-marker:before {position:relative; top:5px;}