header {
    padding: 1em 0 1em 0;
    position: relative;
    z-index: 10;
    background-color: #FFF;
    @media @tablet {
        padding-bottom: 0;
    }

    .logo-link {
        float: left;
    }

    .siteTitle {
        font-size: 2.2em;
        color: @c-secondary;
        letter-spacing: -0.7px;
        line-height: 1;
        margin-bottom: 10px;

        @media @tablet {
            font-size: 1.5em;
        }

        @media @mobile {
            font-size: 1.3em;
        }
    }

    .siteSubTitle {
        font-size: 1.2em;
        color: @c-secondary;

        @media @tablet {
            font-size: 1em;
        }

        @media @mobile {
            font-size: .9em;
        }
    }

    .nhs-logo {
        max-width: 150px;
        width: 100%;
        float: right;
        // align-self: flex-end;
        // margin: 10px;
        display: block;

        @media @desktop {
            max-width: 120px;
        }
    }

    a {
        color: inherit;

        &:hover, &:visited, &:focus {
            text-decoration: none;
            color: inherit;
        }
    }

    .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 5%;
    }
}

.header__container {
    display: flex;
    justify-content: space-between;
    max-width: 970px;
    flex-wrap: wrap;

    &:before, &:after {
        display: none;
    }
}

.header__right {
    flex: 0 1 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media @tablet {
        flex: 0 0 100%;
        margin-top: 10px;
        justify-content: flex-start;
    }

    @media @mobile {
        justify-content: space-between;
    }

    form {
        @media @tablet {
            margin-right: 20px;
        }

        @media @mobile {
            margin: 0;
        }
    }
}

.header__col {
    @media @tablet {
        width: 100%;
        display: flex;
        padding: 0;
    }
    &:last-of-type {
        @media @tablet {
            margin-top: 10px;
        }
    }
}

.header__col-sub {
    @media @tablet {
        flex: 1;
        padding: 0;
        &:first-of-type {
            flex: 0 0 200px;
        }
    }
    &:last-of-type {
        @media @mobile {
            text-align: right;
        }
    }
}