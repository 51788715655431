footer {
    background-color: #e6e6e6;
    color: @c-textcolor;
    font-size: .9em;
    padding: 1em 0 1em 0;
    // text-align: center;
    margin-top: 30px;
    border-bottom: 10px solid @c-textcolor;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        // justify-content: center;
        // overflow: hidden;

        &:before, &:after {
            display: none;
        }

        @media @desktop {
            display: block;
        }
    }

    span {
        margin-right: 20px;
        line-height: 1;
        font-weight: 300;
        // &:last-of-type {
        // 	margin: 0;
        // }
        @media @desktop {
            display: block;
            margin: 0;
            line-height: 1.5;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: inline-block;
        text-align: center;

        @media @desktop {
            margin-top: 10px;
        }

        li {
            display: inline-block;
            margin: 0 0px 0;
            line-height: 1;
            padding-left: 10px;

            :before {
                content: "";
            }

            &:first-child {
                @media @desktop {
                    border: 0;
                    padding-left: 0;
                    margin-left: 0;
                }
            }

            a {
                color: @c-textcolor;
                font-weight: 300;
                font-size:1.3rem;

                &:hover {
                    color: @c-main;
                }
            }
        }
    }
}
footer img {
    margin-left:50px; 
    max-height:65px;
    transform: translateY(15px);
    display: block;

    @media @desktop {
        margin: 20px 0 0 0;
        transform: none;
    }
}

.footer__update {
    margin-bottom: 20px;

    @media @desktop-min {
        position: absolute;
        top: 0;
    }
}