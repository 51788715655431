html, body {
	overflow-x: hidden;
}

body {
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
    color: @c-textcolor;
}

.container {
	width: 100% !important; // bootstrap override
	max-width: 1100px;
}

.header-container {
	z-index: 1;
	position: relative;
}

.wrapper {
   display: flex;
   min-height: 100vh;
	flex-direction: column;
}

main {
   flex: 1 0 auto;
}

.col-md-12 {padding-right:50px; padding-left:50px;}
.col-md-12.sidenav-holder {padding-right:0px; padding-left:0px;}

.lowerpanels {
    margin-bottom: 15px;
    margin-top:15px;
}
.sidecolumn {padding-right:0px;}
.sidenav-holder {margin-top:20px; background:#e5f4f9;}
.sidenav-holder .panel-title { background:@c-main; color:#fff;padding:5px; font-weight:600;}
.quicklink {padding-left:0px;}
.quicklink li {text-indent:10px;}
.quicklink li a:before{content:'';}
.quicklink li a {color:@c-main; border-bottom:1px solid #c0e2f1; width:100%; padding:5px 0;display:block;}


@media only screen and (max-width: 767px) {
    .col-md-12 {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.video-wrapper {
    position: relative;
    padding-top: 52.5%;
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.container {
    .container {
        @media @mobile {
            padding: 0;
        }
    }

    .row {
        @media @mobile {
            margin: 0;
        }
    }

    .col-xxs-12, .col-md-12 {
        @media @mobile {
            padding: 0;
        }
    }
}