.cookieHolder {
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   background-color: @c-secondary;
   color: #FFF;
   text-align: center;
   padding: 20px;
   a {
      background-color: rgba(255,255,255,.3);
      padding: 7px 20px;
      border-radius: 8px;
      display: inline-block;
      margin: 0 0 0 10px;
      font-weight: 600;
      font-size: 1.4rem;
      color: #FFF;
      .transition;
      &:hover {
         text-decoration: none;
         background-color: rgba(255,255,255,.4);
      }
      @media @tablet {
         display: block;
         margin: 5px 0;
         &:first-of-type {
            margin-top: 10px;
         }
         &:last-of-type {
            margin-bottom: 0;
         }
      }
   }
}